import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
            <Route
              path="/"
              element={
                <>
                  <h1>Larry GPT – Skrzydłowy AI w Twoim telefonie 🤖🦸‍♂️💬 </h1>
                  <div className="app-description">
                    <p className="main-subtitle">
                    ✨ Zachwycaj w rozmowach z Larry GPT – asystentem AI, który pomoże Ci zabłysnąć na aplikacjach randkowych.
                    💌 Od odważnych pierwszych wiadomości, przez dowcipne riposty 😂, po płynne odpowiedzi – Larry dopasowuje styl do Ciebie.
                    </p>
                  </div>
                  <div className="features">
                    <div className="feature-item">
                      <span className="feature-icon">🕺</span>
                      <h3>Dostosowane Style</h3>
                      <p>Wybierz styl, który najlepiej oddaje Twoją osobowość.</p>
                    </div>
                    <div className="feature-item">
                      <span className="feature-icon">💬</span>
                      <h3>Odpowiedzi Dopasowane do Kontekstu</h3>
                      <p>Larry analizuje rozmowy, by dostarczyć idealne odpowiedzi.</p>
                    </div>
                    <div className="feature-item">
                      <span className="feature-icon">🚀</span>
                      <h3>Gotowe Pierwsze Wiadomości</h3>
                      <p>Brakuje pomysłu na pierwszy krok? Larry Cię wyręczy.</p>
                    </div>
                  </div>
                  <div className="coming-soon">
                  <p className="store-announcement">Dostępny w App Store!</p>
                  <a href="https://apps.apple.com/pl/app/larrygpt/id6738836316" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="/images/app-store.png" 
                    alt="App Store icon" 
                    className="store-icon" 
                  />
                  </a>
                  </div>
                </>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;